import React from "react";
import { twMerge } from "tailwind-merge";

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ className, children }) => {
  const classes = twMerge(
    "rounded border border-slate-400 px-5 py-4 hover:bg-slate-50 transition-all duration-500 ease-in-out hover:shadow-md",
    className,
  );
  return <div className={classes}>{children}</div>;
};

export default Card;
