import React from "react";
import { Container } from "./";

const Nav: React.FC = () => {
  return (
    <nav className="bg-gray-800 py-4">
      <Container className="flex items-center justify-center py-0 tablet:justify-between tablet:py-0">
        <div className="mr-3.5 text-xl text-slate-100">
          <span className="font-light">lab</span>.
          <span className="font-black">radical</span>
        </div>
        <ul className="hidden items-center tablet:inline-flex">
          <li className="mr-6">
            <a
              href="#root"
              className="text-slate-100 transition ease-in-out hover:text-slate-300"
            >
              Home
            </a>
          </li>
          <li className="mr-6">
            <a
              href="#about"
              className="text-slate-100 transition ease-in-out hover:text-slate-300"
            >
              About
            </a>
          </li>
          <li className="mr-6">
            <a
              href="#services"
              className="text-slate-100 transition ease-in-out hover:text-slate-300"
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="text-slate-100 transition ease-in-out hover:text-slate-300"
            >
              Contact
            </a>
          </li>
        </ul>
      </Container>
    </nav>
  );
};

export default Nav;
