import React from "react";
import { twMerge } from "tailwind-merge";

interface SectionProps {
  className?: string;
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
}

const Section: React.FC<SectionProps> = ({
  className,
  children,
  id,
  style,
}) => {
  const classes = twMerge("", className);
  return (
    <section id={id} className={classes} style={style}>
      {children}
    </section>
  );
};

export default Section;
