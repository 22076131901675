import React from "react";
import { twMerge } from "tailwind-merge";

interface ContainerProps {
  className?: string;
  children: React.ReactNode;
  id?: string;
}

const Container: React.FC<ContainerProps> = ({ className, children, id }) => {
  const classes = twMerge(
    "container mx-auto px-6 py-14 tablet:py-24 tablet:px-0",
    className,
  );
  return (
    <div id={id} className={classes}>
      {children}
    </div>
  );
};

export default Container;
