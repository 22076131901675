import React, { useState, ChangeEvent, FormEvent } from "react";
import { Button, Container, Section } from "./";
import axios from "axios";

const ContactForm: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(e.target.value);
    };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    try {
      await axios.post("/.netlify/functions/sendEmail", {
        name,
        email,
        message,
      });

      setSuccess("Email sent successfully");
      setName("");
      setEmail("");
      setMessage("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (axios.isAxiosError(error) && error.response) {
        setError(`Error: ${error.response.data}`);
      } else {
        setError("Error: Failed to send email");
      }
    }
  };

  return (
    <Section id="contact">
      <Container>
        <form
          onSubmit={handleSubmit}
          className="mx-auto grid max-w-xl grid-cols-1 gap-4"
        >
          <h2 className="mb-4 text-4xl font-semibold">Get in Touch Today</h2>
          {(success || error) && (
            <div
              className={`rounded-md ${success ? "bg-green-300" : "bg-red-300"} px-5 py-3`}
            >
              <p>{success ?? error}</p>
            </div>
          )}
          <input
            type="text"
            value={name}
            onChange={handleInputChange(setName)}
            placeholder="Name"
            className="rounded-md border-2 border-slate-300 p-2"
            required
          />
          <input
            type="email"
            value={email}
            onChange={handleInputChange(setEmail)}
            placeholder="Email"
            className="rounded-md border-2 border-slate-300 p-2"
            required
          />
          <textarea
            value={message}
            onChange={handleInputChange(setMessage)}
            placeholder="Message"
            className="min-h-32 rounded-md border-2 border-slate-300 p-2"
            required
          />
          <div className="mt-4">
            <Button
              type="submit"
              disabled={
                !Boolean(name) ||
                !Boolean(email) ||
                !Boolean(message) ||
                loading
              }
            >
              {loading ? "Sending..." : "Send Message"}
            </Button>
          </div>
        </form>
      </Container>
    </Section>
  );
};

export default ContactForm;
