import React from "react";
import { twMerge } from "tailwind-merge";

type ButtonVariant = "primary" | "secondary" | "outline";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    variant?: ButtonVariant;
    href?: string;
  };

const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  className,
  children,
  href,
  ...props
}) => {
  const baseClasses =
    "inline-block px-8 py-3 rounded-md font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-500 disabled:opacity-50 disabled:bg-slate-300 disabled:cursor-not-allowed";

  const variantClasses = {
    primary: "bg-slate-700 text-white hover:bg-blue-700 focus:ring-blue-500",
    secondary:
      "bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-gray-500",
    outline:
      "border border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-gray-500 hover:text-slate-700",
  };

  const buttonClasses = twMerge(
    `${baseClasses} ${variantClasses[variant]}`,
    className,
  );

  return !href ? (
    <button className={buttonClasses} {...props}>
      {children}
    </button>
  ) : (
    <a className={buttonClasses} href={href} {...props}>
      {children}
    </a>
  );
};

export default Button;
